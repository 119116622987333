<template>
  <div style="overflow: hidden;">
    <Navbar></Navbar>
		<b-container fluid class="banner-section">
  <b-row class="h-100">
    <b-col md="12" class="d-flex justify-content-end align-items-end">
      <div class="login-form-container">
        <h3 style="margin-bottom: 20px; margin-top: 10px;" class="vipti">会员登录</h3>
        <div class="login-options">
          <span 
            :class="['login-option', { active: activeTab === 0 }]"
            @click="activeTab = 0"
          >
            手机号登录
          </span>
          <span 
            :class="['login-option', { active: activeTab === 1 }]"
            @click="activeTab = 1"
          >
            注册账号
          </span>
        </div>
        <div class="login-content">
          <LoginFormPhone v-if="activeTab === 0" />
          <RegisterForm v-if="activeTab === 1" />
        </div>
      </div>
    </b-col>
  </b-row>
</b-container>

		<b-container class="member-service-section">
  <h2 class="text-center vipti">会员服务</h2>
  <p class="text-center">开通会员可享受不同等级的会员权益与服务</p>
  <div class="service-row-wrapper">
    <b-row class="service-row">
      <b-col
        md="4"
        class="mb-3 service-col"
        v-for="(service, index) in services"
        :key="index"
      >
        <div class="service-item" :style="{ borderColor: service.borderColor }">
          <div class="service-item-content">
            <img
              :src="service.img"
              alt="会员等级图标"
              class="service-icon"
            />
            <!-- <p class="service-price">{{ service.price }}</p> -->
            <ul class="service-benefits">
              <li v-for="benefit in service.benefits" :key="benefit" class="text-left servertx" >
                {{ benefit }}
              </li>
            </ul>
          </div>
          <div class="service-button-container">
            <b-button
              :style="{ backgroundColor: service.buttonColor, borderColor: service.buttonColor }"
              variant="primary"
              class="btn"
            >
              立即开通
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</b-container>

<div class="exclusive-benefits-section">
  <h2 class="text-center vipti" style="margin-bottom: 40px;">会员尊享权益</h2>
  <div class="benefits-row-wrapper">
    <b-row class="text-center benefits-row">
      <b-col
        md="4"
        v-for="(benefit, index) in exclusiveBenefits"
        :key="index"
        class="benefit-col"
      >
        <img :src="benefit.icon" alt="权益图标" class="benefit-icon" />
        <p style="margin-top: 20px; font-size: 24px;" class="icontex">{{ benefit.text }}</p>
      </b-col>
    </b-row>
  </div>
  <div class="text-center mt-3">
    <b-button  @click="showRegisterModal" style="border-radius:31px; background-color: #3567E8;border: 0; padding: 10px  20px;">即刻入会  解锁权益 >></b-button>
  </div>
</div>

    <b-modal v-model="registerModalVisible" title="注册账号">
      <RegisterForm />
    </b-modal>
		<Footer></Footer>
  </div>
</template>

<script>
import LoginFormPhone from './LoginFormPhone.vue';
import RegisterForm from './RegisterForm.vue';
import Navbar from '../components/Navbar.vue';
import Footer from '../components/footer.vue';
export default {
  data () {
    return {
      activeTab: 0,
      registerModalVisible: false,
      services: [
        {
          benefits: ['·免费获取科创初级课程', '·免费赠送科创初级课程套件包'],
          img: 'pthy.png',
          borderColor: '#80C4FF',
          buttonColor: '#80C4FF', // 按钮颜色
        },
        {
          benefits: ['·免费学习初级，中级课程', '·免费赠送初级，中级课程套件包', '·免费赠送线下体验课1节'],
          img: 'byhy.png',
          borderColor: '#2A91FF', // 边框颜色
          buttonColor: '#2A91FF', // 按钮颜色
        },
        {
          benefits: ['·免费学习科创系列全套课程', '·免费赠送激光切割三维设计课程', '·免费赠送3个月激光切割三维设计软件', '·免费赠送线下激光切割服务', '·免费赠送线下科创体验课6节'],
          img: 'hjhy.png',
          borderColor: '#FFDA31', // 边框颜色
          buttonColor: '#FFDA31', // 按钮颜色
        },
      ],
      exclusiveBenefits: [
        { icon: 'zkzk.png', text: '提前预订新产品特权' },
        { icon: 'kczk.png', text: '线下体验店特权' },
        { icon: 'xxzk.png', text: '预约维修服务优先' },
      ],
    };
  },
  components: {
    LoginFormPhone,
    RegisterForm,
		Navbar,
    Footer
  },
  methods: {
    showRegisterModal () {
      this.registerModalVisible = true;
    },
  },
};
</script>

<style scoped>
.banner-section {
  background: url('/hybanner.png') no-repeat center center;
  background-size: cover; 
  height: 93vh;
  display: flex;
  align-items: flex-end; 
  justify-content: flex-end; 
}

.login-form-container {
  width: 400px; 
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 120px;
  margin-right: 80px;
}

.member-service-section {
  margin-top: 50px;
}

.service-item {
  background-color: white;
  border-radius: 42px;
  border: 2px solid; 
  height: 400px; 
  display: flex;
  flex-direction: column; 
  justify-content: space-between; 
  box-sizing: border-box;
}
.service-item-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
}

.service-icon {
  width: 100%; 
  height: auto;
  object-fit: contain;
}

.service-price {
  margin-top: 20px; 
}

.service-benefits {
  padding: 0;
  margin:  0;
  list-style: none;
  margin-top: 30px;
}

.service-benefits li {
  margin-bottom: 10px;
}

.service-button-container {
  text-align: center; /* 按钮水平居中 */
}

.btn {
  margin-bottom: 20px; /* 去掉底部外边距 */
}


.exclusive-benefits-section {
  margin-top: 50px;
  padding: 30px 0;
}

.benefit-icon {
  width: 153px;
  height: 153px;
}
.servertx{
margin-left: 15%;
font-size: 16px;
color: #555;
}
@media (max-width: 768px) {
  .service-row-wrapper {
    overflow-x: auto; /* 允许横向滚动 */
  }

  .service-row {
    display: flex;
    flex-wrap: nowrap; /* 防止换行 */
  }

  .service-col {
    flex: 0 0 auto;
    width: 80%; /* 每个项目占据80%的宽度 */
  }

  .service-item {
    margin-right: 10px; /* 项目间距 */
  }
	/* .benefits-row-wrapper {
    overflow-x: auto; 
  } */

  .benefits-row {
    display: flex;
    flex-wrap: nowrap; 
  }

  .benefit-col {
    flex: 0 0 auto;
    width: 30%; /* 每个项目占据80%的宽度 */
    margin-left: 10px;
  }

	.login-form-container {
  width: 300px; 
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
  margin-right: 20px;
}
.servertx{
margin-left: 5%;
}
.benefit-icon {
  width: 60px;
  height: 60px;
}
.icontex{
  font-size: 10px !important;
}
.vipti{
  font-size: 20px;
}
}

.login-options {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
 
}

.login-option {
  color: #999;
  cursor: pointer;
  padding: 0 15px;
  font-size: 16px;
  transition: color 0.3s ease;
  margin-left: 25px;
  margin-right: 30px;
}

.login-option:hover {
  color: #3567E8;
}

.login-option.active {
  color: #3567E8;
  font-weight: 500;
}

.separator {
  color: #555;
  margin: 0 5px;
}

.login-content {
  padding: 20px 0;
}
</style>
