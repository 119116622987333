<template>
  <div>
    <Navbar></Navbar>
    <div class="banner">
      <img src="sec.png"
           alt="">
    </div>

    <!-- 内容区域用 course-container 包裹 -->
    <div class="course-container">
      <div class="course-header">
        <h2 style="font-size: 30px; font-weight: normal; color: #0073EE;" >中泽科教课程</h2>
        <p style="color: black; text-align: justify; font-size: 16px; line-height: 1.8;">中泽教育的科创切割课程体系，是专为青少年设计的一套系统化、模块化的科创教育课程。我们深知，每个孩子都是独一无二的，
          因此我们的课程设计灵活多样，旨在激发每位学生的兴趣和潜能。课程内容包括但不限于：科技基础知识、创新思维训练、项目实践、科学实验等。如果您希望您的孩子能够接受优质的科创教育，
          中泽教育科创切割课程体系将是您的不二选择。我们期待与您携手，共同培养未来的科创人才，为社会的发展贡献力量。</p>
      </div>

      <!-- 搜索框 -->
      <div class="search-section">
        <input type="text"
               v-model="searchQuery"
               placeholder="搜索"
               class="search-input">
      </div>

      <!-- 筛选部分 -->
      <div class="filter-section">
        <div class="filter-header">
          <span style="color: black;font-size: 14px;font-weight: bold;">筛选</span>
          <button class="reset-btn"
                  @click="resetFilters">重置</button>
        </div>
        <hr>
        <div class="filter-group">
          <div class="filter-item">
            <span class="filter-label">适用阶段：</span>
            <span v-for="stage in stages"
                  :key="stage"
                  :class="['filter-text', { active: selectedStage === stage }]"
                  @click="selectStage(stage)">
              {{ stage }}
            </span>
          </div>
          <hr>
          <div class="filter-item">
            <span class="filter-label">产品类别：</span>
            <span v-for="category in categories"
                  :key="category"
                  :class="['filter-text', { active: selectedCategory === category }]"
                  @click="selectCategory(category)">
              {{ category }}
            </span>
          </div>
          <hr>
        </div>
      </div>

      <!-- 课程列表 -->
      <div class="course-grid">
        <div v-if="filteredCourses.length === 0"
             class="no-data">
          <p>没有找到相关课程。</p>
        </div>
        <div v-else
             v-for="(course, index) in filteredCourses"
             :key="index"
             class="course-card"
             :style="{ backgroundColor: course.bgColor }">
          <div class="card-content">
            <div class="course-image">
              <img :src="course.image"
                   :alt="course.title">
            </div>
            <div class="course-info">
              <span class="course-tag"
                    :style="{ color: course.bgColor }">{{ course.curriculumType }}</span>
              <h3>{{ course.title }}</h3>
              <p>{{ course.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <div class="pagination">
        <button class="page-arrow"
                @click="prevPage"
                :disabled="currentPage === 1">‹</button>
        <span v-for="page in totalPages"
              :key="page"
              :class="['page-number', { active: currentPage === page }]"
              @click="goToPage(page)">
          {{ page }}
        </span>
        <button class="page-arrow"
                @click="nextPage"
                :disabled="currentPage === totalPages">›</button>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '../components/footer.vue';
import axios from 'axios';

export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      searchQuery: '',
      stages: ['全部', '中学', '小学', '幼儿'],
      categories: ['全部'], // 初始化仅保留 "全部"，其他数据动态加载
      selectedStage: '全部',
      selectedCategory: '全部',
      currentPage: 1,
      coursesPerPage: 6,
      courses: [], // 存储课程数据
    };
  },
  computed: {
    filteredCourses() {
    const result = this.courses
      .filter((course) => {
        return (
          (this.selectedStage === '全部' || course.stage === this.selectedStage) &&
          (this.selectedCategory === '全部' || course.curriculumType === this.selectedCategory) &&
          (this.searchQuery === '' || course.title.includes(this.searchQuery))
        );
      })
      .slice((this.currentPage - 1) * this.coursesPerPage, this.currentPage * this.coursesPerPage);
    console.log('Filtered Courses:', result);
    return result;
  },
    totalPages() {
      return Math.ceil(this.courses.length / this.coursesPerPage);
    },
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get('/api/produckTable/selectAll');
        const productTypes = response.data.data.map((item) => item.productType);
        this.categories = ['全部', ...new Set(productTypes)]; // 去重后合并
      } catch (error) {
        console.error('Failed to fetch product categories:', error);
      }
    },
    async fetchCourses() {
      try {
        const response = await axios.get('/api/curriculumTable/selectAll');
        this.courses = response.data.data.map((item) => ({
          title: item.curriculumName,
          description: item.curriculumContent,
          image: `/curriculum/${item.curriculumUrl}`,
          curriculumType: item.curriculumType,
          bgColor: item.bgColor,
          stage: item.stage,
        }));
      } catch (error) {
        console.error('Failed to fetch courses:', error);
      }
    },
    selectStage(stage) {
      this.selectedStage = stage;
      this.currentPage = 1;
    },
    selectCategory(category) {
      this.selectedCategory = category;
      this.currentPage = 1;
    },
    resetFilters() {
      this.selectedStage = '全部';
      this.selectedCategory = '全部';
      this.searchQuery = '';
      this.currentPage = 1;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    goToPage(page) {
      this.currentPage = page;
    },
  },
  created() {
    this.fetchCategories();
    this.fetchCourses();
  },
};
</script>


<style scoped>
.course-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.banner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.course-header {
  text-align: center;
  margin: 40px 0;
}

.course-header h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.course-header p {
  color: #666;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.search-section {
  display: flex;
  justify-content: flex-start;
  margin: 30px 0;
}

.search-input {
  width: 300px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 17.71px;
  font-size: 14px;
}

.filter-section {
  margin: 20px 0;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reset-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.filter-group {
  margin-top: 10px;
}

.filter-item {
  margin-bottom: 10px;
  text-align: left;
}

.filter-label {
  margin-right: 10px;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
}

.filter-text {
  margin-right: 15px;
  cursor: pointer;
  color: #000000;
  font-size: 14px;
}

.filter-text.active {
  color: #007bff;
}

.course-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin: 30px 0;
}

.no-data {
  grid-column: span 3;
  text-align: center;
  color: #999;
}

.course-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 300px;
}

.card-content {
  display: flex;
  height: 100%;
}

.course-image {
  width: 50%;
  overflow: hidden;
}

.course-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.course-info {
  width: 60%;
  padding: 20px;
  color: white;
  text-align: left;
}
.course-info h3 {
  font-size: 18px;
  margin-top: 20px;
  /* ...其他样式... */
}

.course-info p {
  font-size: 14px;
  text-align: justify;
  /* ...其他样式... */
}
.course-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  color: inherit;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: 100px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}

.page-arrow {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin: 0 10px;
}

.page-number {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.page-number.active {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

@media (max-width: 768px) {
  .course-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .course-card {
    height: 180px;
  }

  .card-content {
    flex-direction: row;
  }

  .course-image {
    width: 45%;
    height: 100%;
  }

  .course-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .course-info {
    width: 55%;
    padding: 12px;
  }

  .course-info h3 {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 8px;
  }

  .course-info p {
    font-size: 12px;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .course-tag {
    top: 8px;
    right: 8px;
    font-size: 10px;
    padding: 2px 6px;
  }
}

@media (max-width: 768px) {
  .search-section {
    justify-content: center;
  }

  .search-input {
    width: 100%;
    max-width: 300px;
  }
}
</style>
