<template>
  <div>
    <b-form @submit.prevent="onSubmit" style="margin-left: 20%;">
      <b-form-group>
        <b-row class="align-items-center" style="margin-bottom: 20px; margin-top: 20px;">
          <b-col cols="9">
            <b-form-input
              v-model="phone"
              type="tel"
              placeholder="请输入手机号"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <b-row class="align-items-center " style="margin-bottom: 20px;">
          <b-col cols="9">
            <b-form-input
              v-model="password"
              type="password"
              placeholder="请输入密码"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>
    <b-button type="submit" variant="primary" style="width: 235px; margin-right: 100px;">登录</b-button>
      <div class="mt-2">
        <a href="#" style="margin-right: 85px;margin-left: -85px;">注册新用户</a> <a href="#">忘记密码</a>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: '',
      password: '',
    };
  },
  methods: {
    onSubmit() {
      console.log('手机号登录信息:', this.phone, this.password);
    },
  },
};
</script>
