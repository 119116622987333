<template>
	<div style="overflow: hidden;">
		<Navbar></Navbar>
		<b-container fluid class="p-0">
    <!-- 图片 1 -->
    <b-row class="mb-3">
      <b-col>
        <b-img src="scspace1.png" alt="图片1" fluid></b-img>
      </b-col>
    </b-row>
    <!-- 图片 2 -->
    <b-row class="mb-3">
      <b-col>
        <b-img src="scspace2.png" alt="图片2" fluid></b-img>
      </b-col>
    </b-row>
    <!-- 图片 3 -->
    <b-row>
      <b-col>
        <b-img src="scspace3.png" alt="图片3" fluid></b-img>
      </b-col>
    </b-row>
  </b-container>
	
		<Footer></Footer>
	</div>

  
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/footer.vue';
export default {
	components: {
    Navbar,
    Footer
  },
  computed: {
    newsContent() {
      return this.content; // 从路由或父组件获取 HTML 内容
    }
  }
};
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
}
</style>
