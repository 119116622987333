import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import ElementUI from 'element-ui'; // 引入 Element UI 组件库
import 'element-ui/lib/theme-chalk/index.css'; // 引入 Element UI 的样式
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ElementUI)
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
