<template>
  <b-container fluid class="footer-background overlay">
    <b-row class="pt-3 pb-2">
      <b-col cols="12" md="4" class="footer-logo">
       <img src="logo.png" alt="" width="152">
        <p style="margin-right: -45px; font-size: 16px;">联系我们 24小时服务热线<br> <i style="color: #00FCFF; font-size: 16px; margin-right: 85px;">400 986 0998</i></p>
      </b-col>
      <b-col cols="12" md="8">
        <b-row>
          <b-col cols="6" md="2" class="text-left">
            <h5>首页</h5>
            <b-list-group flush class="link-group">
              <b-list-group-item to="#" class="custom-link">关于我们</b-list-group-item>
              <b-list-group-item to="#" class="custom-link">服务范围</b-list-group-item>
              <b-list-group-item to="#" class="custom-link">作品展示</b-list-group-item>
              <b-list-group-item to="#" class="custom-link">新闻资讯</b-list-group-item>
              <b-list-group-item to="/news" class="custom-link">为什么选择我们</b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="6" md="2" class="text-left">
            <h5>科教课程</h5>
            <b-list-group flush class="link-group">
              <b-list-group-item to="/sec" class="custom-link">激光切割课程(初级版)</b-list-group-item>
              <b-list-group-item to="/sec" class="custom-link">激光切割课程(中级版)</b-list-group-item>
              <b-list-group-item to="/sec" class="custom-link">激光切割课程(高级版)</b-list-group-item>
              <b-list-group-item to="/sec" class="custom-link">激光切割三维设计课程</b-list-group-item>
              <b-list-group-item to="/sec" class="custom-link">Good-laser Lightmaker课程</b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="6" md="2" class="text-left">
            <h5>科教空间</h5>
            <b-list-group flush class="link-group">
              <b-list-group-item to="/seSpace" class="custom-link">创客实验室</b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="6" md="2" class="text-left">
            <h5>软件下载</h5>
            <b-list-group flush class="link-group">
              <b-list-group-item to="/Download" class="custom-link">light maker App</b-list-group-item>
              <b-list-group-item to="/Download" class="custom-link">激光切割三维设计软件</b-list-group-item>
              <b-list-group-item to="/Download" class="custom-link">Good-laser Lightmaker软件</b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="6" md="2" class="text-left">
            <h5>在线商城</h5>
            <b-list-group flush class="link-group">
              <b-list-group-item to="/OnlineStore" class="custom-link">在线商城</b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="6" md="2" class="text-left">
            <h5>商务合作</h5>
            <b-list-group flush class="link-group">
              <b-list-group-item to="/business" class="custom-link">商务合作</b-list-group-item>
              <b-list-group-item to="/onlineMessage" class="custom-link">联系我们</b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="footer-legal">
          <p>隐私政策. 用户协议 版权所有 © 中泽教育（深圳）有限公司 <a class="footer-a" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2024288240号-3</a></p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style >
*{
  margin: 0;padding: 0; 
}
.footer-background {
  /* background-image: url('/footer-background.png'); */
  color: #fff;
  position: relative;
}
.overlay {
  background-color: #0073EE; 
}
.footer-logo p {
  margin-top: 10px;
}
.footer-legal {
  font-size: 0.8rem;
  text-align: left;
  border-top: 0.5px solid #409eff;
  padding: 8px 0;
}
.custom-link {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
  font-size: 0.8rem;
  text-decoration: none !important;
  padding: 4px 0 !important;
}

/* 取消鼠标悬停背景色变化 */
.custom-link:hover, 
.custom-link:focus {
  background-color: transparent !important; 
  color: white !important; 
  text-decoration: none !important; 
}

/* 取消点击后的背景色变化 */
.custom-link:active {
  background-color: transparent !important; 
  color: white !important;
}

.link-group {
  display: flex;
  flex-direction: column;
  padding: 0;
  text-decoration: none !important;
  background-color: transparent !important;
  text-align: left;
  white-space: nowrap;
}

/* 移除列表项的边框 */
.list-group-item {
  border: none !important;
  padding: 0.5rem 0 !important;
  background-color: transparent !important;
}

.footer-a{
  color: #fff;
}
.footer-a:hover{
  color: #fff;
}

/* 确保 Bootstrap 的默认样式不会覆盖我们的设置 */
.list-group-flush {
  border: none !important;
}

.list-group-flush .list-group-item {
  border: none !important;
}

</style>
