import Vue from 'vue'
import Router from 'vue-router'
import Index from '../components/index.vue'
import About from '../views/about.vue'
import News from '../views/news.vue'
import NewsDetail from '../views/newsDetail.vue'
import Sec from '../views/sec.vue'
import seSpace from '../views/seSpace.vue'
import Download from '../views/download.vue'
import OnlineStore from '../views/onlineStore.vue'
import Business from '../views/business.vue'
import onlineMessage from '../views/onlineMessage.vue'
import vipServe from '../views/vipServe.vue'
import product from '../views/product.vue'
import software from '../views/software.vue'
import ProductDetail from '../views/ProductDetail.vue.vue'
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    { path: '/',
       redirect: '/index' 
      }, // 添加重定向规则
    { path: '/index', 
      component: Index 
    },
    { path: '/about', 
      component: About 
    },
    { path: '/news', 
      component: News 
    },
    {
      path: '/news-detail',
      name: 'newsDetail',
      component: NewsDetail,
      props: true
    },
    {
      path: '/sec',
      component: Sec,
    },
    {
      path: '/seSpace',
      component: seSpace,
    },
    {
      path: '/Download',
      component: Download,
    },
    {
      path: '/onlineStore',
      component: OnlineStore,
    },
    {
      path: '/business',
      component: Business,
    },
    {
      path: '/onlineMessage',
      component: onlineMessage,
    },
    {
      path: '/vipServe',
      component:vipServe,
    },
    {
      path: '/product',
      component:product,
    },
    {
      path: '/software',
      component:software,
    },
    {
      path: '/product-detail',
      name: 'ProductDetail',
      component: ProductDetail
    }
  ]
    
  
})


