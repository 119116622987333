<template>
  <div style="overflow: hidden;">
    <Navbar></Navbar>
    <!-- Banner 区域 - 全宽 -->
    <b-container fluid class="banner p-0">
      <b-row no-gutters>
        <b-col>
          <img src="onlinestroe.jpg" alt="banner" class="banner-img">
        </b-col>
      </b-row>
    </b-container>

    <!-- 内容区域限制在1200px -->
    <div class="content-wrapper">
      <!-- 导航栏 -->
      <div class="nav-wrapper">
        <b-container class="nav-bar p-0">
          <b-nav justified fill pills >
            <b-nav-item 
              @click="changeTab('crafts')" 
              class="nav-item-left custom-nav-item"
              :active="activeTab === 'crafts'"
            >文创产品</b-nav-item>
            <b-nav-item 
              @click="changeTab('furniture')"
              class="custom-nav-item"
              :active="activeTab === 'furniture'"
            >科教空间</b-nav-item>
            <b-nav-item 
              @click="changeTab('laser')" 
              class="nav-item-right custom-nav-item"
              :active="activeTab === 'laser'"
            >科创设备</b-nav-item>
          </b-nav>
        </b-container>
      </div>

      <!-- 产品展示区域 -->
      <b-container class="products-container" style="margin-bottom: 100px;">
        <b-row class="text-center">
          <b-col md="4" sm="6" v-for="(product, index) in filteredProducts" :key="index" class="mb-4">
            <b-card class="product-card">
              <b-img :src="product.image" :alt="product.alt" fluid class="product-img" />
              <div class="product-name">{{ product.name }}</div>
              <div class="series-name">{{ product.series }}</div>
              
              <div class="button-group">
               
                <b-button 
                  class="action-button"
                  @click="goToProductDetail(product)"
                ><img src="/detail.png" alt="" width="19" style="margin-right: 10px;">产品详情</b-button>
                <b-button  class="action-button"><img src="/gwc.png" alt="" width="19" style="margin-right: 10px;">立即购买</b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/footer.vue';
import axios from 'axios';

export default {
  data() {
    return {
      activeTab: 'crafts', 
      allProducts: {
        laser: [],
        furniture: [],
        crafts: []
      }
    };
  },
  components: {
    Navbar,
    Footer
  },
  computed: {
    filteredProducts() {
      return this.allProducts[this.activeTab] || [];
    }
    
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
      this.$router.replace({ 
        query: { ...this.$route.query, tab: tab }
      });
    },
    async fetchProducts() {
      try {
        const response = await axios.get('/api/admin/img/all');
        const products = response.data.data;
        
        products.forEach(product => {
          const category = product.imgType;
          const newProduct = {
            image: `/static/${product.imgUrl}`,
            series: product.imgDec,
            name: product.imgName,
            alt: product.imgAlt,
            detailImage: product.imgDetail,
          };

          if (!this.allProducts[category]) {
            this.$set(this.allProducts, category, []);
          }

          this.allProducts[category].push(newProduct);
        });
      } catch (error) {
        console.error('获取产品数据失败:', error.response ? error.response.data : error.message);
      }
    },

    goToProductDetail(product) {
      this.$router.push({
        name: 'ProductDetail',
        params: {
          detailImage: product.detailImage,
          imgName: product.imgName
          // `http://192.168.1.25:8082/ProductDetail/${product.detailImage}`,
        },
        query: {
          tab: this.activeTab
        }
      });
    }
  },
  mounted() {
    this.fetchProducts();
    
  },
  created() {
    const tabFromQuery = this.$route.query.tab;
    if (tabFromQuery && ['crafts', 'furniture', 'laser'].includes(tabFromQuery)) {
      this.activeTab = tabFromQuery;
    }
  }
};
</script>

<style scoped>
/* 内容区域限制宽度 */
.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Banner 样式 */
.banner-img {
  width: 100vw;
  height: 90vh;
  object-fit: cover;
}

/* 按钮组样式 */
.button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.action-button {
  border-radius: 6px;
  border: 0;
  width: 129px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3567E8;
}

/* 产品卡片样式 */
.product-card {
  border: 0;
  background-color: #F7F7F7;
  padding: 20px;
  border-radius: 18px;
  width: 100%;
  height: auto;
  min-height: 420px; /* 增加高度以适应两个按钮 */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.product-img {
  height: 200px;
  width: 100%;
  object-fit: contain;
}

.series-name {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
  text-align: center;
}

.product-name {
  font-size: 16px;
  color: #3567E8;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
}

/* 导航栏样式 */
.nav-wrapper {
  width: 100%;
  background: #fff;
}

.nav-bar {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

/* 修改导航项边框样式 */
.nav-item-left {
  border-right: 10px solid #F7F7F7 !important;
  border-left: 0 !important;
}

.custom-nav-item {
  border-left: 10px solid #F7F7F7 !important;
  border-right: 0 !important;
  width: 100%;
  border-bottom: 10px solid #F7F7F7 !important;
}

.nav-item-right {
  border-left: 10px solid #F7F7F7 !important;
  border-right: 0 !important;
}

.nav-item-left::before {
  content: '';
  position: absolute;
  left: -100vw;
  top: 0;
  width: 100vw;
  height: 100%;
  background: inherit;
  border: none;
  /* box-shadow: 0 1px 0 0.5px #919090; */
}

.nav-item-right::after {
  content: '';
  position: absolute;
  right: -100vw;
  top: 0;
  width: 100vw;
  height: 100%;
  background: inherit;
  border: none;
  /* box-shadow: 0 1px 0 0.5px #919090; */
}

/* 保持其他导航项的边框样式 */
.nav-bar .nav-item {
  height: 112.9px;
  line-height: 100px;
  margin-bottom: 50px;
  border: 0.5px solid #e0dddd;
  /* box-shadow: 0 1px 0 0.5px #919090; */
  position: relative;
  background: #fff;
}

.nav-bar .nav-item a {
  height: 100px;
  line-height: 60px;
}

/* 响应式样式 */
@media (max-width: 768px) {
  .content-wrapper {
    padding: 0 15px;
  }

  .product-card {
    height: auto;
    min-height: 450px;
  }

  .product-img {
    height: 150px;
  }

  .button-group {
    flex-direction: column;
    gap: 10px;
  }

  .action-button {
    width: 180px;
    height: 50px;
  }

  .nav-item-left::before,
  .nav-item-right::after {
    display: none; /* 在移动端不显示延伸部分 */
  }
}

/* 导航项默认样式 */
.custom-nav-item {
  color: #333 !important;
  /* border-bottom: 10px solid #F7F7F7 !important; */
}

/* 导航项链接样式 */
.custom-nav-item a {
  color: #333 !important;
  position: relative;
  transition: color 0.3s ease;
}

/* 激活状态样式 - 移除背景色，添加下边框 */
.nav-pills .nav-item .nav-link.active {
  background-color: transparent !important;
  color: #3567E8 !important;
}

/* 添加激活状态的下边框 */
.nav-pills .nav-item .nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%; /* 下边框宽度与导航项等宽 */
  height: 10px;
  background-color: #3567E8;
  /* border-radius: 2px; */
}

/* 鼠标悬停样式 */
.nav-pills .nav-item .nav-link:hover:not(.active) {
  color: #3567E8 !important;
  background-color: transparent;
}

/* 移除 Bootstrap 的默认样式 */
.nav-pills .nav-link {
  border-radius: 0 !important;
}
</style>
