<template>
  <div id="app">
    <Navbar></Navbar>
    <b-carousel id="carousel-1"
                controls
                indicators
                background="#ababab"
                style="text-shadow: 1px 1px 2px #333;"
                class="mb-5 carousel-fullscreen">
      <b-carousel-slide img-src="lb1.jpg"></b-carousel-slide>
      <b-carousel-slide img-src="lb2.jpg"></b-carousel-slide>
    </b-carousel>

    <div class="content-wrapper">
      <!-- 关于我们 -->
      <section class="about-us py-5"
               style="margin-bottom: 100px;">
        <div class="custom-content">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 text-left"
                   style="margin-top: -20px;">
                <div style="display: flex; align-items: center;">
                  <h2 style="font-size: 30px; font-weight: bold; margin-bottom: 30px;color: #333333;">关于我们</h2>
                  <i style="font-size: 18px; margin-left: 0px; margin-bottom: 15px; font-style: normal; color: #555;">/About Us</i>
                </div>
                <p style="line-height:2.5; color: #555;  text-align: justify;"
                   class="aboutt">
                  中泽教育成立于2015年，凭借与香港联通国际的紧密合作，在欧美STEAM课程基础上自主研发适合国内儿童的创客教育体系，通过社会化的学习和实践，
                  让孩子们跨越极限，创造精彩。我们以“STEAM教育+创客空间”为核心，秉承“培养未来创造者”的使命，
                  专注于3-16岁少年儿童的科技创造力教育，开设科创教育空间，自主研发科创教育产品与课程，围绕学习设计软件、激光雕刻、编程等内容板块，
                  形成独特的教育内容研发生态与产品闭环。让孩子们在真实的项目中获得适应未来的核心能力！
                <br>
                  我们的愿景是成为国际科创教育的倡导者，我们提供的不仅是课程，创客环境，更是一种全新的学习方式，
                  我们相信每个孩子都是未来的创新者，让孩子们像科学家一样思考探究，像工程师一样动手实践，像设计师一样创意飞扬，像创客一样学习智造。让他们在探索中发现自我，在实践中成就未来！
                </p>
              </div>
              <div class="col-md-6">
                <img src="/about-us.jpg"
                     class="img-fluid"
                     alt="关于我们图片">
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- 服务范围 - 全宽背景 -->
    <section class="service-area py-5 bg-light">
      <div class="overlay"></div>
      <!-- 内容限制在1200px -->
      <div class="content-wrapper">
        <div class="container text-center"
             style="position: relative; z-index: 1; margin-top: 50px;">
          <h2 
              style="font-size: 30px; font-weight: bold; color: #fff;">服务范围</h2>
          <p 
             style="margin-top: -10px; margin-bottom: 50px; font-size: 18px; color: #fff;">service area</p>
          <p class="text-muted yhua"
             style="display: none;">右滑更多服务信息 -> </p>
          <!-- 包装一个外部 div，用于滚动 -->
          <div class="row services-row">
            <div class="col-md-4 service-col">
              <div class="service-wrapper">
                <!-- <img src="/01.png" class="service-tag" alt="tag"> -->
                <b-card no-body class="service-card">
                  <b-card-img src="/serve1.png"
                              alt="service1"
                              class="rounded"
                              style="border-bottom: 4px solid #F2BC00;"></b-card-img>
                  <b-card-body>
                    <h5 style="font-size: 16px;  color: #333;">构建创客探索空间</h5>
                  </b-card-body>
                </b-card>
              </div>
            </div>
            <div class="col-md-4 service-col">
              <div class="service-wrapper">
                <!-- <img src="/02.png" class="service-tag" alt="tag"> -->
                <b-card no-body class="service-card">
                  <b-card-img src="/serve2.png"
                              alt="service2"
                              class="rounded"
                              style="border-bottom: 4px solid #7DC370;"></b-card-img>
                  <b-card-body>
                    <h5 style="font-size: 16px;  color: #333;">研制科创教育产品和设备</h5>
                  </b-card-body>
                </b-card>
              </div>
            </div>
            <div class="col-md-4 service-col">
              <div class="service-wrapper">
                <!-- <img src="/03.png" class="service-tag" alt="tag"> -->
                <b-card no-body class="service-card">
                  <b-card-img src="/serve3.png"
                              alt="service3"
                              class="rounded"
                              style="border-bottom: 4px solid #3567E8;"></b-card-img>
                  <b-card-body>
                    <h5 style="font-size: 16px; color: #333;">开发创意思维实践课程</h5>
                  </b-card-body>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 回到内容区域 -->
    <div class="content-wrapper">
      <!-- 作品展示 -->
      <section class="works-display py-5"
               style="margin-bottom: 100px;">
        <div class="container text-center">
          <h2 
              style="font-size: 30px; font-weight: bold; color: #333;">作品展示</h2>
          <p style="color: #555; font-size: 18px;">Exhibition of works</p>

          <!-- 手动使用 flex 布局来控制图片排列 -->
          <div class="works-gallery d-flex flex-wrap justify-content-center">
            <div class="works-item"
                 v-for="(image, index) in worksImages.slice(0, 6)"
                 :key="index"
                 style="width: 30%; margin: 1%; cursor: pointer;"
                 @click="ZopenModal(image)">
              <img :src="image.src"
                   class="img-fluid rounded mb-4 works-img">
            </div>
          </div>

          <b-button style="background-color: #3567E8; border: 0; font-size: 18px; padding: 10px; width: 200px;"
                    class="mt-4">查看更多<img src="ArrowRight.svg" alt="" width="34" height="31.46"></b-button>

          <!-- 模态框 -->
          <b-modal v-model="showModal"
                   size="lg"
                   title="作品详情"
                   hide-footer>
            <div class="text-center">
              <img :src="selectedImage.src"
                   class="img-fluid rounded"
                   alt="作品细节">
              <p class="mt-3">{{ selectedImage.description }}</p>
            </div>
          </b-modal>
        </div>
      </section>
    </div>

    <!-- 为什么选择我们 - 全宽背景 -->
    <section class="why-choose-us py-5 bg-light">
      <!-- 内容限制在1200px -->
      <div class="content-wrapper">
        <div class="container text-center">
          <h2 
              style="font-size: 30px; font-weight: bold; color: #333;">
            为什么选择我们
          </h2>
          <p style="color: #555;">Why choose us</p>
          <p class="text-muted yhua"
             style="display: none;">右滑更多信息 -> </p>
          <div class="row features-wrapper">
            <div class="col-md-4 feature-box feature-yellow mb-4 feat" style="margin-left: -20px;">
              <div class="icon-circle yellow">
                <img src="choose.svg" alt="icon1" class="icon-img">
              </div>
              <h5>专业的空间环境设计</h5>
              <p class="text-left">设计团队具备丰富的专业知识和实践经验。我们深刻理解科创教育空间的需求和挑战，并能够为您提供符合实际需求的设计方案。我们的设计师紧跟全球教育空间设计的最新趋势，将创新与实用性相结合，为您的科创教育空间带来更多的可能性。</p>
            </div>
            
            <div class="col-md-4 feature-box feature-green mb-4" style="margin-right: 20px; margin-left: 20px;">
              <div class="icon-circle green">
                <img src="choose2.svg" alt="icon2" class="icon-img">
              </div>
              <h5>先进教育设备的支持与服务</h5>
              <p class="text-left">提供的教育设备采用最新的技术，确保教师和学生能够体验到科技带来的教学和学习革命。我们的设备不仅能够满足日常教学需求，还能支持创新的教学方法和技术应用。</p>
            </div>
            
            <div class="col-md-4 feature-box feature-blue mb-4">
              <div class="icon-circle blue">
                <img src="choose3.svg" alt="icon3" class="icon-img">
              </div>
              <h5>多元化课程体系</h5>
              <p class="text-left">课程设计涵盖了广泛的学科领域，旨在为学生提供全面的教育。无论是科学、文学、艺术还是社会科学，我们都有相应的课程供学生选择。这样的多元化课程体系可以满足不同学生的兴趣和需求，帮助他们发展多方面的技能和知识。我们还不断更新和优化课程内容，以确保学生所学知识与时代发展保持同步。</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 联系我们 - 全宽背景 -->
    <section class="contact-us py-5 ">
      <!-- 内容限制在1200px -->
      <div class="content-wrapper">
        <div class="container">
          <h2 class=" text-center mb-4"
              style="font-size: 30px; font-weight: bold; color: #333;">
            联系我们
          </h2>
          <p class="text-muted"
             style="margin-top: -15px; color: #555;">Contanct Us</p>
          <p class="text-muted yhua"
             style="display: none;">右滑更多联系方式 -> </p>
          <div class="row  contacta">

            <!-- 右侧地图与联系方式部分 -->
            <div class="col-lg-5 col-12 text-center">
              <!-- 地图图片 -->
              <img src="map.png"
                   class="img-fluid mb-3  rounded"
                   alt="地图"
                   style="max-width: 100%; border: 1px solid #ddd;">

              <!-- 联系信息 -->
              <div class="contact-info mt-4 text-left bg-white p-4  rounded">
                <p style="font-size: 20px; color: #333; font-weight: bold; margin-bottom: 15px;">联系方式</p>
                <p style="font-size: 16px; color: #333;">
                  <img src="dianhua.svg"
                       alt="电话"
                       width="24"
                       style="margin-right: 10px;">
                  销售热线: 18379276683 | 服务热线: 400 968 0998
                </p>
                <p style="font-size: 16px; color: #333;">
                  <img src="email.svg"
                       alt="邮箱"
                       width="24"
                       style="margin-right: 10px; margin-bottom: 10px;">
                  邮箱: sam@zz-steam.com
                </p>
                <p style="font-size: 20px; color: #333; font-weight: bold; margin-top: 20px;">业务中心</p>
                <p style="font-size: 16px; color: #333;">
                  <img src="dizhi.svg"
                       alt="地址"
                       width="24"
                       style="margin-right: 10px;">
                  地址: 广东省深圳市福田区八卦四路22号华晟达大厦A座309
                </p>
              </div>
            </div>
            <!-- 左侧表单部分 -->
            <div class="col-lg-7 col-12 mb-4">
              <b-form @submit.prevent="submitForm"
                      class="bg-white p-4  rounded">
                <div class="row">
                  <!-- 姓名 -->
                  <div class="col-md-6 mb-3">
                    <b-form-group label="姓名*"
                                  label-for="input-name"
                                  label-class="text-left"
                                  style="margin-top: -30px;">
                      <b-form-input id="input-name"
                                    v-model="form.name"
                                    placeholder="请输入姓名"
                                    required
                                    ></b-form-input>
                    </b-form-group>
                  </div>
                  <!-- 电话 -->
                  <div class="col-md-6 mb-3">
                    <b-form-group label="电话*"
                                  label-for="input-phone"
                                  label-class="text-left"
                                  style="margin-top: -30px;">
                      <b-form-input id="input-phone"
                                    v-model="form.phone"
                                    placeholder="请输入手机号"
                                    required></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <!-- 邮箱 -->
                <b-form-group label="邮箱"
                              label-for="input-email"
                              label-class="text-left" 
                              style="margin-bottom: 20px;">
                  <b-form-input id="input-email"
                                v-model="form.email"
                                type="email"
                                placeholder="请输入邮箱"></b-form-input>
                </b-form-group>

                <!-- 留言 -->
                <b-form-group label="留言"
                              label-for="input-message"
                              label-class="text-left"
                              >
                  <b-form-textarea id="input-message"
                                   v-model="form.message"
                                   placeholder="请输入留言内容"
                                   style="height: 258px;"></b-form-textarea>
                </b-form-group>

                <!-- 提交按钮，美化样式 -->
                <div class="text-center">
                  <b-button type="submit"
                            variant="primary"
                            class="w-100 py-3"
                            style="background: #0073EE; border: none; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); font-size: 18px;">
                    立即提交
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from './Navbar.vue';
import Footer from './footer.vue';
import axios from 'axios';
export default {
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        message: '',
        captcha: ''
      },
      showModal: false,
      selectedImage: {},
      worksImages: [
        { src: '1.png', description: '机器学习' },
        { src: '2.png', description: '美丽手工' },
        { src: '3.png', description: '猫头鹰时' },
        { src: '4.png', description: '热气球' },
        { src: '5.png', description: '小汽车' },
        { src: '6.png', description: '投影机' }
      ]
    };
  },
  components: {
    Navbar,
    Footer
  },
  methods: {

    ZopenModal(image) {
      this.selectedImage = image;
      this.showModal = true;
    },
    async submitForm() {

      // 准备要发送的数据
      const payload = {
        name: this.form.name,
        phone: this.form.phone,
        mail: this.form.email,
        content: this.form.message,
        state: 0
      };

      try {
        // 发送 POST 请求
        const response = await axios.post('/api/average/save', payload);
        console.log('提交成功:', response.data);
        alert("已成功提交！,请等待我们的回复");
        this.form.name = ''
        this.form.phone = ''
        this.form.email = ''
        this.form.message = ''
      } catch (error) {
        console.error('提交失败:', error.response ? error.response.data : error.message);
        // 可以在此处添加失败提示
      }
    }
  }
};
</script>

<style scoped>
.carousel-fullscreen {
  height: 93vh;
  overflow: hidden;
  background-color: transparent !important;
}

.carousel-fullscreen .carousel-inner {
  height: 100%;
}

.carousel-fullscreen .carousel-item img {
  height: auto;
  object-fit: cover;
  object-position: center;
}

.service-area {
  position: relative;
  height: 55vh;
  background-image: url('/server.png');
  background-size: cover;
  background-position: center;
}

.service-area .overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.71);
  z-index: 0;
}

.service-area .container {
  position: relative;
  z-index: 1;
}

/* 新增的样式 */
.services-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.services-row::-webkit-scrollbar {
  display: none;
}
.service-col {
  flex: 0 0 auto;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .carousel-fullscreen {
    height: 37vh;
  }
  .service-col {
    width: 80%;
  }
  .about-us .row {
    display: flex;
    flex-direction: column !important;
  }
  .features-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-left: 20px;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 15px;
  }

  .feature-box {
    flex: 0 0 80%;
    margin-right: 5px !important;
  }
  .feat {
    margin-left: 20px !important;
  }
  .xauto {
    overflow-x: auto;
  }
  .aboutt {
    font-size: 16px;
    line-height: 1.5 !important;
   
  }
  .contact-us h2 {
    font-size: 30px;
  }
  .contact-us .b-form-group label {
    font-size: 14px;
  }
  .contact-us .b-button {
    font-size: 16px;
    padding: 12px;
  }
  .contact-us .contact-info p {
    font-size: 14px;
  }
  .contacta {
    overflow-x: auto;
  }
  .yhua {
    margin-top: -10px;
    font-size: 10px !important;
    display: block !important;
  }
  .works-display{
  margin-top:100px !important;
}
}
.works-display{
  margin-top:280px ;
}
.works-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.works-item {
  width: 30%;
  margin: 1%;
}

.works-img {
  height: auto;
  max-width: 100%;
}

.feature-box {
  border-radius: 10px;
}

@media (max-width: 768px) {
  .works-gallery {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 10px;
  }

  .works-item {
    min-width: 45%;
    scroll-snap-align: start;
  }
}
.works-img {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.works-img:hover {
  transform: scale(1.1);
}

/* 为什么选择我们 */
.feature-box {
  margin-top: 50px;
  position: relative;
  padding-top: 50px;
  border: 1px solid transparent;
  padding-bottom: 20px;
  background-color: white;
  transition: all 0.3s ease;
}
.row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.feature-box:has(.icon-circle.yellow):hover {
  border-color: #FFDA31;
  box-shadow: 0 0 15px rgba(242, 188, 0, 0.2); /* 添加阴影效果 */
  cursor: pointer;
}

.feature-box:has(.icon-circle.green):hover {
  border-color: #7DC370;
  box-shadow: 0 0 15px rgba(125, 195, 112, 0.2);
  cursor: pointer;
}

.feature-box:has(.icon-circle.blue):hover {
  border-color: #3567E8;
  box-shadow: 0 0 15px rgba(53, 103, 232, 0.2);
  cursor: pointer;
}
.icon-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  border: 4px solid white;
  transition: all 0.3s ease;
}

.icon-img {
  width: 40px;
  height: 40px;
}

.why-choose-us h5 {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.why-choose-us p {
  font-size: 14px;
  color: #666;
}



.service-wrapper {
  position: relative;
  padding-top: 40px; /* 为标签预留空间 */
}

.service-tag {
  position: absolute;
  width: 81.77px;
  right: 10px;
  top: 0;
  z-index: 1;
}

.icon-circle.yellow {
  background-color: #FFDA31;
}

.icon-circle.green {
  background-color: #7DC370;
}

.icon-circle.blue {
  background-color: #3567E8;
}

/* 添加新的样式 */
.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* 移除各个 section 中原有的 container 样式限制 */
.custom-content {
  width: 100%;
}

/* 响应式处理 */
@media (max-width: 1200px) {
  .content-wrapper {
    max-width: 100%;
    padding: 0 20px;
  }
}

/* 内容宽度限制 */
.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* 全宽背景区域 */
.service-area,
.why-choose-us,
.contact-us {
  width: 100%;
  position: relative;
}

/* 响应式处理 */
@media (max-width: 1200px) {
  .content-wrapper {
    max-width: 100%;
    padding: 0 20px;
  }
}

</style>
