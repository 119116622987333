<template>
  <div style="overflow: hidden;">
    <Navbar></Navbar>
    <!-- 全屏横幅图像 -->
    <b-container fluid class="px-0 banner">
      <b-row>
        <b-col>
          <img src="downloadBanner.png" class="img-fluid" alt="横幅图像">
        </b-col>
      </b-row>
    </b-container>
    <b-row class="my-3 text-center dec">
      <b-col>
        <h1 style="font-size: 30px; color: #333; margin-top: 50px; margin-bottom: 10px; ">软件下载</h1>
        <p style="font-size: 16px; color: #555; margin-bottom: 50px;">请根据课程指引下载对应软件</p>
      </b-col>
    </b-row>
    <!-- 软件下载选项 -->
    <b-container class="mt-3 hua">
      <div class="hua-content">
        <div class="hua-item" v-for="item in items" :key="item.id">
          <b-card no-body class="h-100 v-center" style=" background-color: #F7F7F7; border-radius: 40px; width: 350px; border: 0;">
            <div style="margin-top: 45px;">
            <img 
              :src="item.id === 2 && showQRCode ? '/qrcode.png' : item.imgSrc" 
              :alt="item.id === 2 ? '扫码下载' : '软件图像'" 
              top 
              :class="['card-img', { 'qrcode-img': item.id === 2 && showQRCode }]"
            >
            <b-card-body>
              <b-card-title style="margin-bottom:30px; color: #555; font-size: 16px;">{{ item.name }}</b-card-title>
              <b-button @click="handleDownload(item)" block class="Dbut">
                <template v-if="!(item.id === 2 && showQRCode)">
                  <img :src="item.ico" alt="软件图像" top width="27" style="margin-right: 10px;">
                  {{ item.buttonText }}
                  <img :src="item.icon" alt="软件图像" top width="17" style="margin-left: 10px;">
                </template>
                <template v-else>
                  手机扫码下载
                </template>
              </b-button>
            </b-card-body>
          </div>
          </b-card>
        </div>
      </div>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/footer.vue';
export default {
  data() {
    return {
      showQRCode: false,
      items: [
        { 
          id: 1, 
          imgSrc: '/laser.svg', 
          name: 'Good-Laser Lightmaker软件', 
          buttonText: '下载客户端',
          ico: '/win.png',
          icon: '/dow.png',
          downloadUrl: '/upload/Good-LaserLightMaker.zip'
        },
        { 
          id: 2, 
          imgSrc: '/maker.svg', 
          name: 'Light Maker App', 
          buttonText: '下载移动端',
          ico: '/anz.png',
          icon: '/dow.png'
        },
        { 
          id: 3, 
          imgSrc: 'g.svg', 
          name: '激光切割三维设计软件', 
          buttonText: '下载客户端', 
          ico: '/win.png',
          icon: '/dow.png',
          // externalUrl: 'https://www.yuque.com/u2336661/kb'
          externalUrl: 'https://www.i3done.com/zh-cn/product/3done_cut.html'
        }
      ]
    }
  },
  components: { 
    Navbar,
    Footer
  },
  methods: {
    handleDownload(item) {
      if (item.id === 2) {
        this.showQRCode = !this.showQRCode;
      } else if (item.id === 1) {
        // 处理exe文件下载
        const link = document.createElement('a');
        link.href = item.downloadUrl;
        link.download = 'Good-LaserLightMaker.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (item.id === 3) {
        // 在新标签页打开外部链接
        window.open(item.externalUrl, '_blank');
      }
    }
  }
}
</script>

<style scoped>
.banner img {
  width: 100%;
  height: 90vh; /* 全屏高度 */
  object-fit: cover;
}
.card-img {
  padding: 10px;
  width: 150px; /* 宽度自适应 */
  max-height: 150px; /* 控制图片最大高度 */
  object-fit: cover; /* 确保图��不失真 */
  margin-top: 0;
  transition: all 0.3s ease;
}
.Dbut {
  width: 245px;
  height: 62px;
  background-color: #3567E8;
  border: 0;
  font-size: 16px;
  border-radius: 6px;
  margin-bottom: 40px;
}
.v-center {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 400px !important;
  padding: 10px;
}

.hua {
  overflow-x: auto; /* 启用横向滚动 */
  white-space: nowrap; /* 禁止自动换行 */
}
.hua-content {
  display: flex; /* 使用 flexbox 布局来让子项水平排列 */
}
.hua-item {
  flex: 0 0 auto; 
  margin-right: 25px; 
  width: 350px; 
  margin-bottom: 130px;
}

@media (max-width: 768px) {
  .hua-content {
    flex-wrap: nowrap; /* 确保不换行 */
  }
}

.b-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>
